import React from 'react';
import '../css/header.css';

function Header() {
  return (
    <div className="header">
      <h2>UMD Scheduling Bot</h2>
    </div>
  );
}

export default Header;