import React from 'react';
import { useFilter } from '../Contexts/FilterContext';

const FilterChips = () => {
  const { filterChips, removeFilterChip } = useFilter();
  const context = useFilter();  // This will fetch the entire context

  if (!filterChips) {
    console.error('Filter chips are undefined!');
    return null; // Or some fallback UI
    }

  return (
    <div className="filter-chips">
      {filterChips.map((chip, index) => (
        <div key={`chip-${index}`} className="filter-chip" onClick={() => removeFilterChip(chip)}>
          {chip}
          <button><i className="fas fa-times close-icon"></i></button>
        </div>
      ))}
    </div>
  );
};

export default FilterChips;

