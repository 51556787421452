import React, { useState, useEffect } from 'react';
import './css/App.css';
import './css/filterChips.css'
import Sidebar from './Components/Sidebar';
import Header from './Components/Header';
import FilterChips from './Components/FilterChips'
import SampleQuestions from './Components/SampleQuestions'
import axios from 'axios';
import { useTheme } from './Contexts/ThemeContext';
import DisclaimerModal from './Components/DisclaimerModal';
import { useFilter  } from './Contexts/FilterContext';
import showdown from 'showdown';


function App() {
  const [messages, setMessages] = useState([{ text: "Hey Shreya, I see you're a junior CompSci major, do you need help with you schedule for next semester?", sender: 'bot' }]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false)
  const apiUrl = "https://w4escky7nximzw5iykdjwxqo2a0fmhyj.lambda-url.us-east-1.on.aws/"

  const { theme } = useTheme();
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const { filters, filterChips, toggleFilter, clearAllFilters } = useFilter();

  const converter = new showdown.Converter();
  converter.setOption('simpleLineBreaks', true);

  const handleFilterToggle = (filter) => {
    toggleFilter(filter.name);
  };

  const [selectedUser, setSelectedUser] = useState({ id: 1, name: "testusera" });
  const users = [
    { id: 1, name: "testusera" },
    { id: 2, name: "testuserb" },
    { id: 3, name: "testuserc" },
    { id: 4, name: "testuserd" },
    { id: 5, name: "testusere" },
    { id: 6, name: "testuserf" },
    { id: 7, name: "testuserg" }
  ];

  useEffect(() => {
    document.body.className = theme; // Set the class name on the body element
    const accepted = localStorage.getItem('disclaimerAccepted') === 'true';
    setDisclaimerAccepted(accepted);
    setShowDisclaimer(!accepted);
  }, [theme]);

  // Add `disclaimerAccepted` to the dependency array to re-run the effect when it changes.
  useEffect(() => {
    if (disclaimerAccepted) {
      const chatBox = document.querySelector('.chatbox');
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight;
      }
    }
  }, [messages, disclaimerAccepted]);

  

  const handleAgree = () => {
    localStorage.setItem('disclaimerAccepted', 'true');
    setDisclaimerAccepted(true);
    setShowDisclaimer(false);
  };

  const handleClose = () => {
    setShowDisclaimer(false);
  };

  if (!disclaimerAccepted) {
    return <DisclaimerModal isOpen={showDisclaimer} onAgree={handleAgree} onClose={handleClose} />;
  }
  

  // Handles the input change
  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  // Handles sending a message
  const handleSend = async () => {
    if (input.trim() !== '') {
      const newMessages = [...messages, { text: input, sender: 'user' }];
      setMessages(newMessages);
      setInput('');
      setLoading(true);
      let messageText = input;
      if (filterChips.length > 0) {
        messageText += ` [Filters: ${filterChips.join(', ')}]`; // Append filters to the message text
      }
      console.log(messageText)

      try {
        // Modify the URL and body as per your API's requirements
        const response = await axios.post(apiUrl, {
          "question": messageText,
          "sessionId": window.globalUUID,
          "student_id": selectedUser.name
        });
        console.log("response: ", response)

        setLoading(false);
        const botResponse = { text: converter.makeHtml(response.data.response), sender: 'bot' };
        setMessages(prev => [...prev, botResponse]);

        // Additional processing if response contains more data
        console.log("full response:", response);
        clearAllFilters();
      } catch (error) {
        console.error("Error in getting response:", error);
        setLoading(false);
        const errorMessage = { text: "Failed to get response from the server.", sender: 'bot' };
        setMessages(prev => [...prev, errorMessage]);
      }
    }
  };

  const handleQuestionSelect = async (question) => {
    setLoading(true);
    const newMessages = [...messages, { text: question, sender: 'user' }];
    setMessages(newMessages);
    let messageText = question;
    if (filterChips.length > 0) {
      messageText += ` [Filters: ${filterChips.join(', ')}]`;
    }
    console.log(messageText)
  
    try {
      const response = await axios.post(apiUrl, {
        "question": messageText,
        "sessionId": window.globalUUID,  // This should be dynamically set if it varies
        "student_id": selectedUser.name    // This should also be dynamically set
      });
      console.log("response: ", response)
  
      setLoading(false);
      const botResponse = { text: converter.makeHtml(response.data.response), sender: 'bot' };
      setMessages(prev => [...prev, botResponse]);
      clearAllFilters();
    } catch (error) {
      console.error("Error in getting response:", error);
      setLoading(false);
      const errorMessage = { text: "Failed to get response from the server.", sender: 'bot' };
      setMessages(prev => [...prev, errorMessage]);
    }
  };
  

  return (
    <div className={theme === 'light' ? 'light-app' : 'dark-app'}>
      <div className="App">
        <Sidebar users={users} selectedUser={selectedUser} onUserSelect={setSelectedUser}/>
        <div className="chat-container">
        <Header />
            <div className="chatbox">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}-message`}>
                  <div className="message-text" dangerouslySetInnerHTML={{__html: msg.text}}/>
                </div>
              ))}
              {loading && (
                  <div className="spinner-container">
                  <div className="loader"></div>
                </div>
              )}
            </div>
            <div className="all-inputs">
            <SampleQuestions onQuestionSelect={handleQuestionSelect}/>
            <div className="input-container">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={event => event.key === 'Enter' ? handleSend() : null}
                placeholder="Type a message..."
              />
              <button onClick={handleSend} className="send-button">
                Send
              </button>
            </div>
            <FilterChips />


            </div>
        </div>
      </div>
    </div>
  );
}

export default App;



