import React, { createContext, useContext, useState, useEffect } from 'react';

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState(() => {
    const sessionData = sessionStorage.getItem('filters');
    return sessionData ? JSON.parse(sessionData) : [
      { name: "No Friday Classes", isActive: false },
      { name: "No Morning Classes", isActive: false },
      { name: "No Evening Classes", isActive: false },
      { name: "Only Highly Rated Professors", isActive: false }
    ];
  });
  const [filterChips, setFilterChips] = useState(
    filters.filter(f => f.isActive).map(f => f.name) // Initialize filter chips based on active filters
  );
  
  

  useEffect(() => {
    sessionStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    setFilterChips(filters.filter(f => f.isActive).map(f => f.name));
  }, [filters]);

  useEffect(() => {
  }, [filters, filterChips]);
  

  const addFilter = newFilter => {
    setFilters(prevFilters => [...prevFilters, { name: newFilter, isActive: true }]);
  };

  const removeFilter = filterName => {
    setFilters(prevFilters => prevFilters.filter(filter => filter.name !== filterName));
    setFilterChips(prevChips => prevChips.filter(chip => chip !== filterName));
  };

  const toggleFilter = filterName => {
    setFilters(prevFilters => prevFilters.map(filter => {
      if (filter.name === filterName) {
        const isActive = !filter.isActive;
        return { ...filter, isActive };
      }
      return filter;
    }));

    setFilterChips(prevChips => {
      const chipExists = prevChips.includes(filterName);
      if (chipExists) {
        return prevChips.filter(chip => chip !== filterName);
      } else {
        return [...prevChips, filterName];
      }
    });
  };
  
  
  const removeFilterChip = filterName => {
    setFilterChips(prevChips => prevChips.filter(chip => chip !== filterName));
    toggleFilter(filterName);
  };

  const clearAllFilters = () => {
    setFilters(prevFilters => prevFilters.map(filter => ({ ...filter, isActive: false })));
    setFilterChips([]); // Clear all filter chips
  }; 
  

  return (
    <FilterContext.Provider value={{ filters, addFilter, removeFilter, toggleFilter, removeFilterChip, filterChips, clearAllFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => useContext(FilterContext);