import React from 'react';
import { useFilter } from '../Contexts/FilterContext';
import '../css/filterBubble.css'; 

const FilterBubble = ({text, isActive}) => {
  const { toggleFilter } = useFilter();
  const handleToggle = () => {
    toggleFilter(text);  // Pass the filter name to the toggle function
  };

  return (
    <div className="bubble">
      {text}
      <label className="switch">
        <input type="checkbox" checked={isActive} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default FilterBubble;