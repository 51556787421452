import React from 'react';
import '../css/disclaimerModal.css';  // Assume you will add styles here

const DisclaimerModal = ({ isOpen, onClose, onAgree }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="disclaimer-modal">
      <div className="disclaimer-content">
        <h2>Disclaimer</h2>
        <p>Please read and agree to the terms of use before proceeding:</p>
        <p>[Insert Disclaimer Here]</p>
        <button onClick={onAgree}>I Agree</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default DisclaimerModal;