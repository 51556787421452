import React from 'react';
import '../css/sidebar.css';
import { useTheme } from '../Contexts/ThemeContext'; // Import useTheme
import { useFilter } from '../Contexts/FilterContext';
import FilterBubble from '../Components/FilterBubble';

const Sidebar = ({ users, selectedUser, onUserSelect }) => {
  const { theme, toggleTheme } = useTheme(); // Use toggleTheme from the context
  const { filters} = useFilter();

  return (
    <div className="sidebar">
      <h2>Settings</h2>
      <div>
        <h3>Select User</h3>
        <select className="dropdown"
          value={selectedUser.id}
          onChange={(e) => onUserSelect(users.find(user => user.id === parseInt(e.target.value)))}>
          {users.map(user => (
            <option key={user.id} value={user.id}>{user.name}</option>
          ))}
        </select>
      </div>
      <div className="theme-switch-wrapper">
        <h3>{theme === 'light' ? 'Light Side' : 'Dark Side'}</h3>
        <label className="theme-switch">
          <input
            type="checkbox"
            onChange={toggleTheme}
            checked={theme === 'dark'} // Checkbox is checked if theme is dark
          />
          <div className="slider round"></div>
        </label>
      </div>
      <div className="filter-wrapper">
        <h3>Filters</h3>
          <div>
            {filters.map((filter) => (
              <FilterBubble key={filter.name} text={filter.name} isActive={filter.isActive}/>
            ))}
          </div>
      </div>
      <div className="other-chatbots-wrapper">
        <h3>Explore More Chatbots at UMD</h3>
          <a href="https://billpay.umd.edu/" target="_blank" rel="noopener noreferrer" className="chatbot-link-bubble">
            SFSC Bot
          </a>
          <a href="https://health.umd.edu/" target="_blank" rel="noopener noreferrer" className="chatbot-link-bubble">
            Health Center Bot
          </a>
      </div>
      {/* <nav>
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">Settings</a></li>
          <li><a href="#">Help</a></li>
        </ul>
      </nav> */}
    </div>
  );
};

export default Sidebar;