import React, { useState }from 'react';
import '../css/sampleQuestions.css';

const SampleQuestions = ({ onQuestionSelect }) => {
    const initialQuestions = ["Help me plan next semesters schedule?", "How many credits have I already earned?", "What classes do I have left for my major?"];
    const [questions, setQuestions] = useState(initialQuestions);

    const handleQuestionClick = (question) => {
        onQuestionSelect(question);  // Propagate the selection upwards

        if (question === "Help me plan next semesters schedule?") {
            setQuestions(["I don't want Friday classes", "Am I on course to graduate with this schedule?", "How many classes will I have left after that schedule?"]);
        } else if (question === "How many credits have I already earned?") {
            setQuestions(["Detailed credits breakdown", "Credits needed for graduation", "Transfer credits status"]);
        } else {
            setQuestions(initialQuestions);  // Reset or set to another set of questions
        }
    };

    return (
      <div className="sample-questions">
        {questions.map((question, index) => (
          <button key={index} className="question-bubble" onClick={() => handleQuestionClick(question)}>
            {question}
          </button>
        ))}
      </div>
    );
};

export default SampleQuestions;
